import Swiper, { Autoplay, EffectFade } from "swiper";

Swiper.use([Autoplay, EffectFade]);
const swiper = new Swiper(".hero__slider", {
  slidesPerView: "auto",
  loop: true,
  autoplay: {
    delay: 3000,
    disableOnInteraction: false,
  },
  effect: "fade",
});

import {
  gsap,
  ScrollTrigger,
  Draggable,
  MotionPathPlugin,
  Flip,
} from "gsap/all";

gsap.registerPlugin(ScrollTrigger, Draggable, MotionPathPlugin, Flip);

let btnAnimation = () => {
  const tlMessageBtn = gsap.timeline({
    defaults: { duration: 0.1 },
    repeat: -1,
    repeatDelay: 3,
  });
  tlMessageBtn
    .to(".btn__subscribe", { rotate: 5 })
    .to(".btn__subscribe", { rotate: -5 })
    .to(".btn__subscribe", { rotate: 5 })
    .to(".btn__subscribe", { rotate: 0 })
    .to(".btn-mob__subscribe", { rotate: 5 })
    .to(".btn-mob__subscribe", { rotate: -5 })
    .to(".btn-mob__subscribe", { rotate: 5 })
    .to(".btn-mob__subscribe", { rotate: 0 });
  return tlMessageBtn;
};
const tl = gsap.timeline({ defaults: { duration: 0.5 } });
tl.from(".btn__subscribe", { opacity: 0, y: 30 }).add(btnAnimation);
